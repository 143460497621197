export const environment = {
  production: false,
  apiServerUrl: 'https://tournity-api.dev.paywith.co.kr',
  fileServerUrl: 'https://tournity-api.dev.paywith.co.kr/file',
  searchParamKeyCamelize: false,
  showPackagegoodsScheduleFixedDataFrom: '2024-08-15 10:00:00',
  showPackagegoodsScheduleFixedDataTo: '2024-08-16 15:30:00',
  booking2025From: '2025-03-09 00:00:00',
  booking2025To: '2025-12-12 00:00:00',
  season1From: '2024-10-05 16:00:00',
  // season1To: '2024-10-09 23:59:59',
  season1To: '2024-10-08 23:59:59',
  // season2From: '2024-10-14 10:00:00',
  season2From: '2024-10-09 10:00:00',
  season2To: '2024-10-16 23:59:59',
  // season3From: '2024-10-28 10:00:00',
  season3From: '2024-10-21 10:30:00',
  // season3To: '2024-10-28 23:59:59',
  season3To: '2024-10-28 10:59:59',
  packagegoodsId: {
    // 야마나미 상품코드
    yamanami: 1,
    // 야마나미 간지  상품코드
    kangi: 97,
    // 야마나미 키라라   상품코드
    kirara: 98,
    // aso 시즈노야도   상품코드
    sizunoyado: 11,
    // 14힐즈 상품코드
    nagoya: 2,
    // 뱃푸  상품코드
    beppu: 4,
    // 나가노스가타하라  상품코드
    nagano: 12,
    // 아오모리토와타  상품코드
    aomori: 13,
    // 이바라키 시로사토 상품코드
    sirosato: 95,
    // 이바라키 후쿠로다노타키 상품코드
    hukurodanoki: 50,
    ibarakiSirosatoFromIncheon: 108,
    ibarakiSirosatoFromBusan: 109,
    hukurodanokiFromIncheon: 106,
    hukurodanokiFromBusan: 107,
  },
  hotelGoodsId: {
    yamanami: {
      // 트윈
      twn: 1,
      // 소형트윈
      sTwn: 845,
      // 인근숙소
      out: 849,
    },
    kangi: {
      twn: 848,
    },
    kirara: {
      kirara: 853,
    },
    sizunoyado: {
      sizunoyado: 925,
    },
    nagoya: {
      twn: 2,
      qud: 846,
      karaban: 1022,
    },
    beppu: {
      twn: 11,
      new: 847,
    },
    nagano: {
      twn: 921,
    },
    aomori: {
      in: 922,
      out: 923,
    },
    sirosato: {
      twn: 1015,
      qud: 1028,
    },
    hukurodanoki: {
      twn: 927,
    },
  },
};
